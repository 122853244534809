import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResourceOperation } from "modules/shared/application/types";
import {
  BaseFiltersSlice,
  baseFiltersSlice,
} from "modules/shared/infrastructure/api/baseFiltersSlice";
import { RootState } from "modules/shared/infrastructure/redux/auditStore";

export interface DictionariesSlice extends BaseFiltersSlice {
  selectedDictionaryId?: number;
  operation: ResourceOperation;
}

const initialState: DictionariesSlice = {
  ...baseFiltersSlice.getInitialState(),
  operation: "read",
  selectedDictionaryId: undefined,
};

export const dictionariesFilterSlice = createSlice({
  name: "dictionariesFilter",
  initialState,
  reducers: {
    ...baseFiltersSlice.caseReducers,
    setResourceOperation: (state, action: PayloadAction<ResourceOperation>) => {
      state.operation = action.payload;
    },
    setSelectedDictionaryId: (state, action: PayloadAction<number>) => {
      state.selectedDictionaryId = action.payload;
    },
    resetFilter: () => {
      return initialState;
    },
  },
});

export const dictionariesSelector = (state: RootState) => state.dictionaries;

export const {
  setSearchTerm,
  setOrder,
  setOrderBy,
  setTake,
  setPage,
  setSource,
  setOpenModal,
  setResourceOperation,
  setSelectedDictionaryId,
} = dictionariesFilterSlice.actions;

export default dictionariesFilterSlice.reducer;
