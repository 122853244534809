import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageFilterOptions } from "../types";
import { Order, OrderBy, Source } from "modules/shared/application/types";

export interface BaseFiltersSlice {
  filters: PageFilterOptions;
  openModal: boolean;
}

const initialState: BaseFiltersSlice = {
  filters: {
    searchTerm: "",
    order: "desc",
    orderBy: "createdAt",
    take: 10,
    page: 1,
    source: "",
    startDate: null,
    endDate: null,
  },
  openModal: false,
};

export const baseFiltersSlice = createSlice({
  name: "baseFiltersSlice",
  initialState,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.filters.searchTerm = action.payload;
    },
    setOrder: (state, action: PayloadAction<Order>) => {
      state.filters.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<OrderBy>) => {
      state.filters.orderBy = action.payload;
    },
    setTake: (state, action: PayloadAction<number>) => {
      state.filters.take = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    setSource: (state, action: PayloadAction<Source>) => {
      state.filters.source = action.payload;
    },
    setOpenModal: (state, action: PayloadAction<boolean>) => {
      state.openModal = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string | null>) => {
      state.filters.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string | null>) => {
      state.filters.endDate = action.payload;
    },
  },
});
