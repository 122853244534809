import { configureStore } from "@reduxjs/toolkit";
import authReducer from "modules/user/infrastructure/repository/auth/authSlice";
import operationOutcomesReducer from "modules/operationOutcomes/infrastructure/repository/operationOutcomesSlice";
import dictionariesReducer from "modules/dictionaries/infrastructure/repository/dictionariesSlice";
import { auditApi } from "../api/auditApi";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    operationOutcomes: operationOutcomesReducer,
    dictionaries: dictionariesReducer,
    [auditApi.reducerPath]: auditApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(auditApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
