import {
  ArrowLeftStartOnRectangleIcon,
  BookOpenIcon,
  CircleStackIcon,
  HomeIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import SidebarItem from "./SidebarItem";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  authSelector,
  removeToken,
} from "modules/user/infrastructure/repository/auth/authSlice";
import SidebarExpandButton from "./SidebarExpandButton";

const sidebarItems = [
  { label: "Home", path: "/", icon: <HomeIcon /> },
  {
    label: "Resultados",
    path: "/operationOutcomes",
    icon: <CircleStackIcon />,
  },
  { label: "Diccionarios", path: "/dictionaries", icon: <BookOpenIcon /> },
];

function Sidebar() {
  const { userData } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  function toggleExpanded() {
    setIsExpanded((prev) => !prev);
  }

  return (
    <div
      className={`transition-all duration-300 flex flex-col h-full ${
        isExpanded ? "w-60 px-4" : "w-20 px-2"
      } pb-4 bg-white border-l border-slate-200 shadow-xl relative`}
    >
      <SidebarExpandButton
        isExpanded={isExpanded}
        toggleExpanded={toggleExpanded}
      />
      <div className="flex items-center justify-center h-36">
        <span className="font-medium text-sm text-center text-wrap">
          FHIR Auditoría
        </span>
      </div>
      <div className="flex flex-col w-full h-full items-start justify-between">
        <div
          className={`flex flex-col ${
            isExpanded ? "items-start" : "items-center"
          } gap-2.5 w-full`}
        >
          {sidebarItems.map((item) => (
            <Link key={item.path} className="w-full" to={item.path}>
              <SidebarItem
                label={item.label}
                icon={item.icon}
                currentRoute={location.pathname === item.path}
                expanded={isExpanded}
              />
            </Link>
          ))}
        </div>
        <div className="w-full flex flex-col items-start gap-2">
          <div
            className={`w-full h-12 gap-1 pl-2 transition-opacity duration-200 flex border-b select-none ${
              isExpanded ? "opacity-100" : "opacity-0"
            }`}
          >
            <div className="flex-shrink-0 w-10 h-full flex items-center justify-center text-slate-400">
              <UserCircleIcon />
            </div>
            <div className="flex-1 w-40 h-full flex flex-col items-start justify-center">
              <span className="block w-full text-start text-sm font-medium text-tyopography-primary overflow-hidden truncate">
                {userData?.name}
              </span>
              <span className="block w-full text-start text-sm text-tyopography-primary overflow-hidden truncate">
                {userData?.email}
              </span>
            </div>
          </div>
          <div className="w-full">
            <SidebarItem
              label="Salir"
              icon={<ArrowLeftStartOnRectangleIcon />}
              currentRoute={false}
              expanded={isExpanded}
              onClick={() => dispatch(removeToken())}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
