import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { useAppSelector } from "hooks";
import { authSelector } from "modules/user/infrastructure/repository/auth/authSlice";

function Layout() {
  const { accessToken } = useAppSelector(authSelector);

  return accessToken ? (
    <div className="flex flex-row w-full h-screen transition-all">
      <Sidebar />
      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" />
  );
}

export default Layout;
