import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { loginUser, registerUser } from "./authActions";
import { RootState } from "modules/shared/infrastructure/redux/auditStore";

export interface AuthError {
  errors: {
    _form?: {
      message: string;
      statusCode: number;
    };
  };
}

export interface UserData {
  id: number;
  name: string;
  email: string;
}

export interface AuthTokenPayload {
  userId: number;
  userEmail: string;
  userName: string;
  iat: number;
  exp: number;
}

export interface AuthState {
  loading: boolean;
  error: AuthError | null;
  success: boolean;
  accessToken: string | undefined;
  userData: UserData | null;
}

const initialState: AuthState = {
  loading: false,
  error: null,
  success: false,
  accessToken: localStorage.getItem("accessToken") ?? undefined,
  userData:
    (JSON.parse(localStorage.getItem("userData") ?? "{}") as UserData) ?? null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    removeToken: (state) => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userData");
      state.accessToken = undefined;
      state.loading = false;
      state.error = null;
      state.success = false;
      state.userData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.accessToken = payload;
      const decodedToken = jwtDecode<AuthTokenPayload>(payload);
      const userData = {
        id: decodedToken.userId,
        name: decodedToken.userName,
        email: decodedToken.userEmail,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
      state.userData = userData;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as AuthError;
    });
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.accessToken = payload;
      const decodedToken = jwtDecode<AuthTokenPayload>(payload);
      const userData = {
        id: decodedToken.userId,
        name: decodedToken.userName,
        email: decodedToken.userEmail,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
      state.userData = userData;
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as AuthError;
    });
  },
});

export const authSelector = (state: RootState) => state.auth;

export const { removeToken } = authSlice.actions;

export default authSlice.reducer;
