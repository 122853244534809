import { lazy, Suspense } from "react";
import { PathConstants } from "./paths";
import { RouteObject } from "react-router-dom";
import Layout from "modules/shared/presentation/layouts/Layout";
import AnonymousLayout from "modules/shared/presentation/layouts/AnonymousLayout";
import Loader from "modules/shared/presentation/components/Loader";
import ErrorPage from "pages/ErrorPage";

const HomePage = lazy(() => import("pages/HomePage"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const DictionariesPage = lazy(() => import("pages/DictionariesPage"));
const OperationOutcomesPage = lazy(() => import("pages/OperationOutcomesPage"));

const LazyWrapper = ({
  component: Component,
}: {
  component: React.ComponentType;
}) => (
  <Suspense
    fallback={
      <div className="flex justify-center items-center h-screen w-full">
        <Loader size="large" />
      </div>
    }
  >
    <Component />
  </Suspense>
);

const routes: RouteObject[] = [
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: PathConstants.HOME,
        element: <LazyWrapper component={HomePage} />,
      },
      {
        path: PathConstants.operationOutcomes,
        element: <LazyWrapper component={OperationOutcomesPage} />,
      },
      {
        path: PathConstants.DICTIONARIES,
        element: <LazyWrapper component={DictionariesPage} />,
      },
    ],
  },
  {
    element: <AnonymousLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: PathConstants.LOGIN,
        element: <LazyWrapper component={LoginPage} />,
      },
    ],
  },
];

export default routes;
