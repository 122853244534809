import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
const API_VERSION = process.env.REACT_APP_API_VERSION ?? "v1";
const API_KEY = process.env.REACT_APP_API_KEY ?? "";

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/${API_VERSION}`,
  timeout: 15000,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    apikey: API_KEY,
  },
});

export async function fetchData<T>(
  url: string,
  options: AxiosRequestConfig = {},
): Promise<T> {
  const response: AxiosResponse<T> = await axiosInstance(url, options);
  return response.data;
}
